import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle, SecondaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import { get } from "lodash"
import getSlug from "src/parsers/slug-parser"

export const pageQuery = graphql`
  {
    allStrapiBlogPosts(filter: { publish: { eq: true } }) {
      nodes {
        title
        slug
        date(formatString: "MMMM D, YYYY")
        author {
          Name
          picture {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(height: 30, quality: 95, layout: FIXED)
              }
            }
          }
        }
        cover_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(height: 240, quality: 80, layout: FIXED)
            }
          }
        }
      }
    }
  }
`

/* eslint-disable import/no-anonymous-default-export, limited-exports-page-templates, no-anonymous-exports-page-templates */
export default ({ data }) => {
  const blogCms = data.allStrapiBlogPosts.nodes
  const blog = blogCms
    .sort((a, b) => new Date(b.date) - new Date(a.date))

  const [observed, setObserved] = useState(false)
  const articleRefs = []

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo
        title="Blog - animal rights and advocacy, activism"
        description="Follow our blog to stay informed about our work and successes, animal and industry news, and the goals of Anima International. Get to know us better!"
      />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">Blog</PrimaryTitle>
      </PageHeader>
      <Container>
        <ArticleList>
          {blog.map(({ title, slug, author, date, cover_image }, index) => {
            const [articleObserved, setArticleObserved] = useState(false)

            const articleRef = {
              current: (articleRef) => (articleRefs[index] = articleRef),
            }

            useIntersectionObserver({
              callback: (change) => {
                if (change.isIntersecting) {
                  setArticleObserved(true)
                }
              },
              elementRef: articleRef,
            })

            return (
              <Article
                ref={articleRef}
                className={articleObserved ? "-observed" : ""}
                key={index}
              >
                <ArticleInner to={`/blog/${getSlug({ slug, title })}`}>
                  {cover_image && (
                    <ArticleImageWrapper>
                      {get(
                        cover_image,
                        "localFile.childImageSharp.gatsbyImageData"
                      ) ? (
                        <ArticleImageSharp
                          image={get(
                            cover_image,
                            "localFile.childImageSharp.gatsbyImageData"
                          )}
                          alt={title}
                        />
                      ) : (
                        <ArticleImage
                          src={cover_image.localFile.publicURL}
                          loading="lazy"
                        />
                      )}
                    </ArticleImageWrapper>
                  )}
                  <ArticleContent>
                    <ArticleTitle>{title}</ArticleTitle>
                    <ArticleMeta>
                      {author && (
                        <ArticleAuthor>
                          <ArticleAuthorPhotoWrapper>
                            {author.picture ? (
                              author.picture.localFile.childImageSharp ? (
                                <ArticleAuthorPhotoSharp
                                  image={get(
                                    author,
                                    "picture.localFile.childImageSharp.gatsbyImageData"
                                  )}
                                  alt={author.Name}
                                />
                              ) : (
                                <ArticleAuthorPhoto
                                  src={author.picture.localFile.publicURL}
                                  alt={author.Name}
                                />
                              )
                            ) : (
                              <ArticleNoAuthorIcon name="ok-bird" />
                            )}
                          </ArticleAuthorPhotoWrapper>
                          <ArticleAuthorName>{author.Name}</ArticleAuthorName>
                        </ArticleAuthor>
                      )}
                      <ArticleDate>{date}</ArticleDate>
                    </ArticleMeta>
                  </ArticleContent>
                </ArticleInner>
              </Article>
            )
          })}
        </ArticleList>
      </Container>
    </>
  )
}

export const PageHeader = styled.header`
  padding: 180px 0 50px;
  position: relative;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding: 250px 0 100px;
  }
`

export const Container = styled(BaseContainer)`
  max-width: 872px;
  margin-bottom: 155px;

  ${media.tablet} {
    margin-bottom: 215px;
  }
`

export const ArticleList = styled.div``

export const Article = styled.article`
  margin-bottom: 20px;
  position: relative;
  transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

  &::before {
    content: "";
    opacity: 0;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    background: linear-gradient(-20deg, #3ab6da, #50d9d6);
    border-radius: 3px;
    box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);
    pointer-events: none;
    transition: opacity 0.15s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    z-index: 1;
    background: #fff;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 100px;
  }
`

export const ArticleInner = styled(Link)`
  display: block;
  padding: 20px;
  border: 1px solid #cfdbdb;
  border-radius: 3px;

  ${media.tablet} {
    display: flex;
    padding: 35px;
  }
`

export const ArticleImageWrapper = styled.div`
  min-width: 240px;
  width: 100%;
  height: calc((100vw - 42px) / 1.5);
  margin-right: 35px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  transition: 0.6s 0.25s ${(p) => p.theme.easing.easeOutQuart};

  ${Article}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    width: 240px;
    height: 240px;
    margin-bottom: 0;
  }
`

export const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
`

export const ArticleImageSharp = styled(GatsbyImage)`
  width: 100% !important;
  height: 100% !important;
  border-radius: 3px;
`

export const ArticleContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-bottom: 70px; */
  /* padding-top: 15px; */
  /* padding-bottom: 10px; */
  padding-top: 10px;

  position: relative;
  z-index: 2;

  ${media.tablet} {
    padding-bottom: 15px;
  }
`

export const ArticleTitle = styled(SecondaryTitle)`
  line-height: 1.5;
  transition: 0.6s 0.35s ${(p) => p.theme.easing.easeOutQuart};

  ${Article}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }
`

export const ArticleMeta = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  z-index: 2;
  font-size: 15px;
  line-height: 1;
  color: ${(p) => p.theme.color.textLight};

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    article${Article}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(1) {
    transition-delay: 0.45s;
  }

  > :nth-child(2) {
    transition-delay: 0.55s;
  }
`

export const ArticleAuthor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  position: relative;
`

export const ArticleAuthorPhotoWrapper = styled.div`
  height: 42px;
  margin-right: 12px;
  padding: 2px;
  position: relative;
  background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 50%;
  /* box-shadow: 0 16px 16px rgba(3, 34, 33, 0.08); */

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 4px solid #fff;
    border-radius: 50%;
  }
`

export const ArticleAuthorPhoto = styled.img`
  width: 38px;
  height: 38px;
  border: 4px solid #fff;
  border-radius: 50%;
`

export const ArticleAuthorPhotoSharp = styled(GatsbyImage)`
  width: 38px !important;
  height: 38px !important;
  border: 4px solid #fff;
  border-radius: 50%;
`

export const ArticleNoAuthorIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  margin: 11px;
  color: #fff;
`

export const ArticleAuthorName = styled.p`
  font-weight: 500;
  color: #000;
`

export const ArticleDate = styled.p`
  display: none;

  ${media.tablet} {
    display: block;
  }
`
